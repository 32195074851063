

.cookiesPopUpContainer {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 14px;
  z-index: 9999999;
  border: 1px solid;
  width: 506px;
  max-height: 550px;

  @media screen and (max-width: 768px) {
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: min(570px, 70vh);
    border-radius: unset;
    border: unset;
  }
}