.btn {
  @apply inline-block whitespace-nowrap rounded-md px-7 py-[13px] text-[15px] font-semibold transition-colors duration-200;

  &.btn-sm {
    @apply px-10 sm:py-[15px];
  }

  &.btn-md {
    @apply px-10 sm:py-[17px];
  }

  &-icon {
    @apply inline-flex w-full max-w-full items-center justify-between py-[13px] sm:px-6 sm:py-[15px];
  }
}

.btn-primary {
  @apply border border-primary bg-primary text-white hover:bg-opacity-80;
}

.btn-outline-primary {
  @apply border border-primary bg-white text-black hover:bg-primary hover:text-white;
}
